import React, { useMemo } from 'react';
import { get } from 'lodash';

import { IOrderView } from '@interfaces/Order';
import { PRODUCT_BONUS_AMOUNT } from '@config/constants';
import { getBase64ImageSrc } from '@utils/getBase64ImageSrc';
import styles from './OrderProduct.module.scss';
import lemonIcon from '@assets/happyPointsIcon.png';
import { calculateOrderTotal } from '@store/slices/order/order.utils';
import { displayDollarsAmount } from '@utils/displayDollarsAmount';

import { CloseButton, PromoCard } from '@components/Common';
import { PromoCardEnum } from '@components/Common/PromoCard/PromoCard';

type PropsType = {
  order: IOrderView;
  productsRewardId: number[];
  onUseBonuses(orderId: string, value: number): void;
  onUnUseBonuses(orderId: string): void;
  onRemoveOrder(orderId: string): void;
  onUpdateOrder(orderId: string): void;
  appliedBonuses: Record<string, number>;
  isAuthorized: boolean;
  pointsBalance: number;
  isPromoActive: boolean;
};

const OrderProduct: React.FC<PropsType> = ({
  order,
  productsRewardId,
  onUseBonuses,
  onUnUseBonuses,
  appliedBonuses,
  isAuthorized,
  pointsBalance,
  onRemoveOrder,
  onUpdateOrder,
  isPromoActive
}) => {
  const { product, toppings, id, quantity, options } = order;
  const bonusApplied = get(appliedBonuses, id);
  const insufficientBonusBalance = pointsBalance < PRODUCT_BONUS_AMOUNT;

  const orderTotal = useMemo(() => calculateOrderTotal(order), [order]);

  let orderPriceWithPromo = null;

  const onUnUse = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onUnUseBonuses(id);
  };

  const onUse = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onUseBonuses(id, 10 * quantity);
  };

  if (order.promo?.value && order.promo.quantity) {
    switch (order.promo.type) {
      case 1:
        orderPriceWithPromo = orderTotal - order?.promo?.value * order.promo.quantity;
        break;
      case 3:
        orderPriceWithPromo =
          orderTotal - ((order.product.price * order.promo.value) / 100) * order.promo.quantity;
        break;
    }
  }

  const renderButtons = () => {
    if (!productsRewardId.includes(product.id)) return null;

    if (!isAuthorized || !product.type) {
      return null;
    }

    if (bonusApplied) {
      return (
        <button className={styles.unUseBonus} onClick={onUnUse}>
          <img src={lemonIcon} alt="lemon" />
          <span>-{bonusApplied}</span>
          <p>Remove</p>
        </button>
      );
    }

    return (
      <button className={styles.bonusButton} onClick={onUse} disabled={insufficientBonusBalance}>
        <img src={lemonIcon} alt="lemon" />
        <span>Use {PRODUCT_BONUS_AMOUNT * quantity}</span>
      </button>
    );
  };

  return (
    <>
      <div className={styles.container} onClick={() => onUpdateOrder(id)}>
        {order.promo?.promoAction === 2 && (
          <span className={styles.promo}>
            {order.promo?.type === 1 && `$ ${order.promo.value} off on ITEM`}
            {order.promo?.type === 3 && `%${order.promo.value} off on ITEM`}
          </span>
        )}
        <div className={styles.header}>
          <img
            className={styles.productImg}
            src={getBase64ImageSrc(product.imgSrc)}
            alt={product.title}
          />
          <div className={styles.title}>
            <h2>{product.title}</h2>
            <p className={styles.orderQuantity}> x {order.quantity}</p>
          </div>
          <CloseButton className={styles.close} onClick={() => onRemoveOrder(id)} />
        </div>

        <div className={styles.body}>
          <div className={styles.optionsBox}>
            {options.map((option) => (
              <p key={option.id}>
                {option.title}: <span>{option.value}</span>
              </p>
            ))}
          </div>
          {!!toppings.length && <h4>Options:</h4>}
          {toppings.map(({ topping, quantity }, index) => (
            <div className={styles.topping} key={index}>
              <p>
                {topping.description} x {quantity}
              </p>
              <p>{displayDollarsAmount(topping.price * quantity)}</p>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <div className={styles.price}>
            <h4 className={`${orderPriceWithPromo !== null && styles.crossed}`}>
              {displayDollarsAmount(orderTotal)}
            </h4>
            {orderPriceWithPromo !== null ? (
              orderPriceWithPromo !== 0 ? (
                <h2>{displayDollarsAmount(orderPriceWithPromo || 0)}</h2>
              ) : (
                <h2>Free</h2>
              )
            ) : null}
          </div>
          {!isPromoActive && renderButtons()}
        </div>
      </div>
      {order.promo && order.promo.promoAction !== 2 && (
        <PromoCard type={PromoCardEnum.checkout} promo={order.promo} />
      )}
    </>
  );
};

export default React.memo(OrderProduct);
