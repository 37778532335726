import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { prepareOrderPayload, preparePaymentPayload } from '@store/slices/payment/payment.utils';
import { RootState } from '@store/store';
import {
  appliedBonusesSelector,
  // cardPayValueSelector,
  ordersSelector,
  promotionsAmountValue,
  selectedGiftCardsSelector,
  subtotalSelector,
  taxesSelector,
  tipValueSelector,
  totalSelector,
} from '@store/slices/order/order.selector';
import ls from '@utils/ls';
import storage from '@config/storage';
import { IApiProduct } from '@interfaces/ProductsResponse';
import paymentApi from '@api/payment.api';
import orderApi from '@api/order.api';
// import RewardApi from '@api/rewards.api';
import { getShopDataFromLocalStorage } from '../shop/shop.utils';
import { authSelector } from '../auth/auth.selectors';
import { shopSelector } from '../shop/shop.selectors';
import { giftCardsSelector } from '../member/member.selector';
import externalPayId from '@utils/externalPayId';
import { setTip } from '../order/order.slice';

export const startPayment = createAsyncThunk(
  'payment/startPayment',
  async (
    { signal, onSuccess }: { signal: AbortSignal; onSuccess: () => void },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const orders = ordersSelector(state);
    const total = totalSelector(state);
    const promoTotalValue = promotionsAmountValue(state)
    const tips = tipValueSelector(state);
    // const totalGiftCard = cardPayValueSelector(state);

    const rowProducts = ls.get<IApiProduct[]>(storage.LS.PRODUCTS);
    const idempotencyKey = uuidv4();
    const externalPaymentId = externalPayId();

    const shopInfoFromLS = getShopDataFromLocalStorage();

    if (!rowProducts) {
      throw new Error('Stored products not found');
    }

    const payload = preparePaymentPayload(orders, rowProducts);

    if (!shopInfoFromLS) return null;

    const cloverPayCredentials = {
      apiToken: shopInfoFromLS.clover_api_token,
      deviseId: shopInfoFromLS.clover_device_id,
      posId: shopInfoFromLS.pos_id,
      cloverIP: shopInfoFromLS.clover_pay_url,
    };

    let uploadTransData = {
      ...payload,
      shared_pointer_id: shopInfoFromLS.shared_pointer_id,
      tip: !tips ? 0 : tips,
      Amount: '',
      CardType: '',
      CardNo: '',
      EntryType: '',
      AuthCode: '',
      ReferenceId: '',
      cloverPayId: '',
      rewards: []
    };

    console.log(JSON.stringify(uploadTransData), (total - promoTotalValue) * 100 )

    const checkConnect = await fetch(`http://${shopInfoFromLS.upload_trans_url}/check_connect`, {
      method: 'POST',
    }).then((data) => {
      return data.status;
    });

    if (checkConnect === 200) {
      const response = await paymentApi.ping(cloverPayCredentials);
      // eslint-disable-next-line no-console
      console.log(response);
      if (response['connected']) {
        const { response: tip } = await paymentApi.readTip(cloverPayCredentials, total - promoTotalValue, signal);
        dispatch(setTip(tip / 100));
        const payResponse = await paymentApi
          .salesPay(
            {
              ...cloverPayCredentials,
              idempotencyKey,
            },
            {
              amount: Math.round((total - promoTotalValue) * 100 + 0.00001),
              taxAmount: 0,
              tipAmount: tip,
              externalPaymentId,
            },
            signal
          )
          .finally(() => {
            setTimeout(() => {
              paymentApi.welcome(cloverPayCredentials);
            }, 1500);
          });
        // eslint-disable-next-line no-console

        uploadTransData = {
          ...uploadTransData,
          ...payResponse,
        };

        await fetch(`http://${shopInfoFromLS.upload_trans_url}/upload_trans`, {
          signal,
          method: 'POST',
          body: JSON.stringify(uploadTransData),
        }).then((data) => {
          return data;
        });
      }
    }
    onSuccess();
    dispatch(setTip(0));
  }
);

export const startPaymentWithMember = createAsyncThunk(
  'payment/startPaymentWithMember',
  async (
    { signal, onSuccess }: { signal: AbortSignal; onSuccess: () => void },
    { getState, dispatch }
  ) => {
    const state = getState() as RootState;
    const shop = shopSelector(state);
    const auth = authSelector(state);
    const subtotalWithoutBonuses = subtotalSelector(state);
    const selectedGiftCards = selectedGiftCardsSelector(state);
    const bonuses = appliedBonusesSelector(state);
    const taxes = taxesSelector(state);

    const orders = ordersSelector(state);
    const giftCards = giftCardsSelector(state);
    const total = totalSelector(state);
    const promoTotalValue = promotionsAmountValue(state)
    // const totalGiftCard = cardPayValueSelector(state);

    const rowProducts = ls.get<IApiProduct[]>(storage.LS.PRODUCTS);
    const idempotencyKey = uuidv4();
    const externalPaymentId = externalPayId();

    const shopInfoFromLS = getShopDataFromLocalStorage();

    if (!rowProducts) {
      throw new Error('Stored products not found');
    }

    if (!shopInfoFromLS) return null;

    const cloverPayCredentials = {
      apiToken: shopInfoFromLS.clover_api_token,
      deviseId: shopInfoFromLS.clover_device_id,
      posId: shopInfoFromLS.pos_id,
      cloverIP: shopInfoFromLS.clover_pay_url,
    };

    const createOrderData = {
      member_info: {
        member_id: auth.memberId,
        name: auth.memberName,
      },
      shop_id: shop.id,
      tips: 0,
      instruction: '',
      customer_phone: auth.memberPhone,
      customer_name: auth.memberName,
      total: subtotalWithoutBonuses + taxes,
      rewards: [],
      article_list: prepareOrderPayload(orders, rowProducts, bonuses),
      gift_card_payments: selectedGiftCards.map((c) => {
        for (const s of giftCards) {
          if (s.cardNumber === c) {
            return {
              card_number: c,
              face_value: s.balance,
            };
          }
        }
      }),
    };

    console.log((total - promoTotalValue).toFixed(2), (total - promoTotalValue).toFixed(2) === '0.00', total, promoTotalValue, 'keys')

    if ((total - promoTotalValue).toFixed(2) === '0.00') {
      const createOrder = await orderApi.createOrder(createOrderData, signal);

      await fetch(`http://${shopInfoFromLS.upload_trans_url}/upload_trans`, {
        method: 'POST',
        body: JSON.stringify({
          ...createOrder.data.selfOrder,
          shared_pointer_id: shopInfoFromLS.shared_pointer_id,
        }),
      }).then((data) => {
        return data;
      });

    } else {
      const createOrder = await orderApi.createOrder(createOrderData, signal);
      let selfOrderData = createOrder.data.selfOrder;

      const response = await paymentApi.ping(cloverPayCredentials);
      // eslint-disable-next-line no-console
      console.log(response);

      if (response['connected']) {
        const { response: tip } = await paymentApi.readTip(cloverPayCredentials, total - promoTotalValue, signal);

        dispatch(setTip(tip / 100));
        const payResponse = await paymentApi
          .salesPay(
            {
              ...cloverPayCredentials,
              idempotencyKey,
            },
            {
              amount: Math.round(createOrder.data.shouldBePayedByPos * 100),
              taxAmount: 0,
              tipAmount: tip,
              externalPaymentId,
            },
            signal
          )
          .finally(() => {
            setTimeout(() => {
              paymentApi.welcome(cloverPayCredentials);
            }, 1500);
          });
        // eslint-disable-next-line no-console

        selfOrderData = {
          ...selfOrderData,
          ...payResponse,
          tip: tip,
        };
        await fetch(`http://${shopInfoFromLS.upload_trans_url}/upload_trans`, {
          signal,
          method: 'POST',
          body: JSON.stringify(selfOrderData),
        }).then((data) => {
          return data;
        });
      }

    }
    onSuccess();
    dispatch(setTip(0));
  }
);

export const checkPaymentMethods = createAsyncThunk('payment/checkPaymentMethods', async () => {});

export const cancelPayment = createAsyncThunk('payment/cancelPayment', async () => {
  const shopInfoFromLS = getShopDataFromLocalStorage();

  if (!shopInfoFromLS) return null;

  await paymentApi
    .cancel({
      apiToken: shopInfoFromLS.clover_api_token,
      deviseId: shopInfoFromLS.clover_device_id,
      posId: shopInfoFromLS.pos_id,
      cloverIP: shopInfoFromLS.clover_pay_url,
    })
    .then(() => {
      setTimeout(() => {
        paymentApi.welcome({
          apiToken: shopInfoFromLS.clover_api_token,
          deviseId: shopInfoFromLS.clover_device_id,
          posId: shopInfoFromLS.pos_id,
          cloverIP: shopInfoFromLS.clover_pay_url,
        });
      }, 1500);
    });
});
