import {
  IOrder,
  IOrderOptionView,
  IOrderProduct,
  IOrderTopping,
  IOrderToppingView,
  IOrderView,
} from '@interfaces/Order';
import { IProduct } from '@interfaces/Product';
import { ITopping } from '@interfaces/Topping';
import { ProductVariantsEnum } from '@interfaces/enums';
import {
  IProductOption,
  IProductOptionsGroup,
  ProductVariantsOptionsType,
} from '@interfaces/ProductOption';
import { ITip, ITipTemplate } from '@interfaces/Tips';
import { getAmountFromPercentage } from '@utils/getAmountFromPercentage';
import { IGiftCard } from '@interfaces/GiftCard';
import { calculateGiftCardBalance } from '@store/slices/member/member.utils';
import { IPromotion } from '@interfaces/Promotions';

export const getOrderOptionsView = (
  selectedOptions: Record<string, string | number>,
  options: IProductOption[],
  productOptions: IProductOptionsGroup[]
): IOrderOptionView[] => {
  return Object.keys(selectedOptions)
    .map((key) => {
      const id = selectedOptions[key];
      const group = productOptions.find((o) => o.id === key);
      const option = options.find((o) => o.id === id);

      if (!group || !option) {
        return null;
      }

      return {
        id,
        title: group.description,
        value: option.description,
      };
    })
    .filter(Boolean) as IOrderOptionView[];
};

export const getToppingsView = (
  orderToppings: IOrderTopping[],
  toppings: ITopping[]
): IOrderToppingView[] => {
  return orderToppings
    .map((orderTopping) => {
      const topping = toppings.find((t) => t.id === orderTopping.toppingId);

      if (!topping) {
        return null;
      }

      return {
        ...orderTopping,
        topping,
      };
    })
    .filter(Boolean) as IOrderToppingView[];
};

export const getOrderView = (
  order: IOrder,
  product: IProduct,
  toppings: ITopping[],
  options: IProductOption[],
  productVariantsOptions: Record<number, ProductVariantsOptionsType>
): IOrderView => {
  const productToppings = getToppingsView(order.toppings, toppings);
  const productOptions = productVariantsOptions[product.id][order.productVariant];

  const selectedOptions = productOptions?.length
    ? getOrderOptionsView(
        order.options,
        options,
        productVariantsOptions[product.id][order.productVariant] || []
      )
    : [];
  const { productVariant, quantity, categoryId, id } = order;
  const { invalid_end, invalid_start } = product;

  return {
    id,
    product: mapToOrderProduct(product, order.productVariant),
    toppings: productToppings,
    quantity,
    categoryId,
    productVariant,
    options: selectedOptions,
    invalid_end,
    invalid_start,
    promo: order.promo
  };
};

export const getOrderProductsView = (
  orders: IOrder[],
  products: Record<number, IProduct[]>,
  toppings: ITopping[],
  options: IProductOption[],
  productOptions: Record<number, ProductVariantsOptionsType>
): IOrderView[] => {
  return orders
    .map((order) => {
      const product = products[order.categoryId].find((p) => p.id === order.productId);

      if (!product) {
        return null;
      }

      return getOrderView(order, product, toppings, options, productOptions);
    })
    .filter(Boolean) as IOrderView[];
};

export const getOrderTotal = (
  tip: null | number,
  subtotal: number,
  taxes: number,
  cardPay: number
) => Number(tip) + subtotal + taxes - cardPay;

export const getAppliedBonusesValue = (appliedBonuses: Record<number, number>) =>
  Object.values(appliedBonuses).reduce((acc, curr) => acc + curr, 0);

export const getPointBalanceAfterBonuses = (balance: number, appliedBonusesValue: number): number =>
  Math.trunc(balance - appliedBonusesValue);

export const mapToOrderProduct = (
  product: IProduct,
  variant: ProductVariantsEnum
): IOrderProduct => {
  const { id, imgSrc, type, price, title, vat } = product;
  let productPrice = price[variant];
  let productVat = vat[variant];
  let productTitle = title[variant];

  if (productTitle === undefined || productPrice === undefined || productVat === undefined) {
    productPrice = price[ProductVariantsEnum.Default];
    productVat = vat[ProductVariantsEnum.Default];
    productTitle = title[ProductVariantsEnum.Default];
  }

  if (productTitle === undefined || productPrice === undefined || productVat === undefined) {
    throw new Error('Unsupported product variant');
  }

  return {
    id,
    imgSrc,
    type,
    price: productPrice,
    vat: productVat,
    title: productTitle,
  };
};

export const calculateOrderTotal = (order: IOrderView, isBonusApplied?: boolean) => {
  const toppingsTotal = order.toppings.reduce((res, item) => {
    return item.topping.price * item.quantity + res;
  }, 0);
  const productPrice = isBonusApplied ? 0 : order.product.price;

  return (toppingsTotal + productPrice) * order.quantity;
};

export const calculateOrdersSubtotalWithBonuses = (
  orders: IOrderView[],
  appliedBonuses: Record<string, number>
) => {
  return orders.reduce((res, order) => {
    const isBonusApplied = !!appliedBonuses[order.id];

    return res + calculateOrderTotal(order, isBonusApplied);
  }, 0);
};

export const calculateOrdersSubtotal = (orders: IOrderView[]) => {
  return orders.reduce((res, order) => res + calculateOrderTotal(order), 0);
};

export const calculateTax = (vat: number, price: number) => {
  if (vat === 0) {
    return 0;
  }

  return price * (vat / 100);
};

export const getToppingsTaxes_ = (toppings: IOrderToppingView[]) => {
  return toppings.reduce((result, { topping, quantity }) => {
    return result + quantity * calculateTax(topping.vat, topping.price);
  }, 0);
};

export const getTotalTaxes = (orders: IOrderView[], appliedBonuses: Record<string, number>) => {
  return orders.reduce((res, order) => {
    const isBonusApplied = !!appliedBonuses[order.id];
    const toppingTaxes = getToppingsTaxes_(order.toppings);
    let productTax = 0
    
    for(let i = 0; i < order.quantity; i++) {
      if (order.promo?.quantity && order.promo.quantity > i) {
        productTax += isBonusApplied ? 0 : calculateTax(order.product.vat, order.product.price - order.promo.quantity);
      } else {
        productTax += isBonusApplied ? 0 : calculateTax(order.product.vat, order.product.price);
      }
    }
    const orderTax = toppingTaxes + productTax

    return res + orderTax;
  }, 0);
};

export const getOrderToppingsUsage = (toppings: IOrderTopping[]) => {
  return toppings.reduce((res, topping) => res + topping.quantity, 0);
};

export const getTips = (tipsTemplate: ITipTemplate[], subtotal: number): ITip[] => {
  return tipsTemplate.map(({ id, text, percentage }) => ({
    id,
    title: text,
    value: getAmountFromPercentage(percentage, subtotal),
  }));
};

export const getProductsQuantityInTheCart = (orders: IOrder[]) => {
  return orders.reduce((acc, order) => acc + order.quantity, 0);
};

export const getTotalValueUsedByCard = (
  tip: null | number,
  subtotal: number,
  taxes: number,
  selectedCards: string[],
  giftCards: IGiftCard[],
  promoAmoutn: number
): number => {
  const filteredCards = giftCards.filter((card) => selectedCards.includes(card.cardNumber));
  const sortedCards = [...filteredCards].sort(
    (a, b) => calculateGiftCardBalance(b) - calculateGiftCardBalance(a)
  );

  let payment = 0;
  let remainingTotal = Number(tip) + subtotal - promoAmoutn + taxes;

  for (let i = 0; i < sortedCards.length; i++) {
    const card = sortedCards[i];
    const availableBalance = calculateGiftCardBalance(card);

    if (availableBalance >= remainingTotal) {
      payment += remainingTotal;
      break;
    } else {
      payment += availableBalance;
      remainingTotal -= availableBalance;
    }
  }

  return payment;
};

export const getAllOrderProductsRewardId = (rewards: any[]) => {
  if (!rewards) return [];
  return rewards.map((item) => item.article_id);
};

export const getProductsForPromotion = (promotions: IPromotion[], products: IProduct[]) => {
  const promoProducts: Record<string, Record<string, IProduct[]>> = {};

  promotions.forEach((promo) => {
    promo.groups.forEach((group) => {
      let filteredProduct: IProduct[] = []
      group.items.forEach((item) => {
        if (item.groupId) {
          const filtered = products.filter(
            (product) => product.group_letter_id[+item.groupLetter - 1] === item.groupId
          );

          filteredProduct = [...filteredProduct, ...filtered]
        }

        if (item.articleId) {
          const findProduct = products.find(product => product.id === item.articleId)
          if (findProduct) filteredProduct.push(findProduct)
        }
      });

      if (promo.actions[0].promotionItemGroupId === group.id) {
        promoProducts[promo.id] = {for: filteredProduct, ...promoProducts[promo.id]}
      } else {
        promoProducts[promo.id] = {to: filteredProduct, ...promoProducts[promo.id]}
      }
    });
  });
  return promoProducts;
};

export const getMaxPromotions = (promotions: IPromotion[]) => {
  const maxPromotions: Record<string, number> = {}

  promotions.forEach(promo => {
    maxPromotions[promo.id] = promo.maxPromotions
  })

  return maxPromotions
}


export const getPromotionsAmountValue = (orders: IOrderView[]) => {
  let amount = 0;

  orders.forEach(order => {
    if (!order.promo ) return;

    const {value, type, quantity } = order.promo;

    if (!value) return;

    if (type === 1) {
      amount += value * quantity;
      return
    }

    amount += (value  / 100) * order.product.price * quantity
  })
  return amount
}

export const getPromoProductActivationAmount = (promotions: IPromotion[]) => {
  const productActivationAmount: Record<string, Record<string, number>> = {}

  promotions.forEach(promo => {
    promo.groups.forEach(group => {
      if (group.id === promo.actions[0].promotionItemGroupId) {
        productActivationAmount[promo.id] = { for: group.fromQuantity, ...productActivationAmount[promo.id]}
      } else {
        productActivationAmount[promo.id] = { to: group.fromQuantity, ...productActivationAmount[promo.id]}
      }
    })
  })

  return productActivationAmount
}